import { Category } from './category';
import { DocumentAttributes, ProjectContract } from './contract';
import { Project } from './project';

export interface ProbeTry {
  x: number;
  y: number;
  z: number;
  ac: number;
  me?: number;
  de?: number;
  imc?: number;
  rc?: number;
}

export enum PROBE_STATUS {
  NEW = 'NEW',
  RECEIVED = 'RECEIVED',
  TESTED = 'TESTED',
  READY = 'READY',
  FINAL = 'FINAL'
}

export enum PROBE_TYPE {
  RC = 'RC',
  RI = 'RI'
}
export interface Probe {
  project: Project;
  category: Category;
  pvp?: ProjectContract;
  report?: ProjectContract;
  attributes?: DocumentAttributes;
  id?: string;
  no?: string;
  pno?: string;
  extra?: string;
  created?: Date;
  updated?: Date;
  deletedAt?: Date;
  try1?: ProbeTry;
  try2?: ProbeTry;
  try3?: ProbeTry;
  mediumDensity?: number;
  mediumRC?: number;
  evaluation?: string;
  localization?: string;
  aspect?: string;
  registryDate?: Date;
  tryDate?: Date;
  registryEvaluation?: string;
  registryManager?: string;
  tryType?: string;
  registryPno: string;
  clientCompanyName: string;
  canceled?: string;
  status?: PROBE_STATUS;
  type?: PROBE_TYPE;
  docDate?: Date;
}

export interface ProbeRegistryBody {
  data: {
    registryDate: Date;
    registryManager: string;
    registryEvaluation: string;
  };
  attributes: DocumentAttributes;
  ids: string[];
}
