import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Lab } from 'src/types/lab';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/connect" />;
  }

  if (isAuthenticated && !user?.hasAccess) {
    return <Redirect to="/no-access" />;
  }

  if (user.isClient) {
    return <Redirect to="/client" />;
  }

  if (!user.isClient && !user.role?.id) {
    return <Redirect to="/no-access" />;
  }

  if (!user.laboratory || !user.workstation) {
    return <Redirect to="/set-workstation" />;
  }

  if (user.laboratories?.length && !user.laboratories.find((lab: Lab) => lab.id === user.laboratory?.id)) {
    return <Redirect to="/set-workstation" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
