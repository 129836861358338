/* eslint-disable no-param-reassign */
/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Order } from 'src/types/order';
import { ProjectProduct } from 'src/types/product';
import { DATE_FORMAT } from 'src/utils/formatDate';

import { Box, Card, CardContent, CardHeader, Divider, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { KeyboardDatePicker } from '@material-ui/pickers';

import OrderCreateProductsList from './OrderCreateProductsList';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { projectId: string };
}

const OrderCreateModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<Order>(new Order());
  const [loading, setLoading] = useState<{ deps: boolean; saving: boolean }>({ deps: false, saving: false });

  const loadDependences = useCallback(async () => {
    try {
      setLoading({ ...loading, deps: true });
      const project = await api.projects.get(extra?.projectId);
      if (project) {
        const laboratory = { ...project?.laboratory };
        const client = { ...project?.client };
        const site = { ...project?.site };
        delete project.laboratory;
        delete project.client;
        delete project.site;
        setData({ ...data, project, laboratory, client, site });
      }
    } catch (e) {
      enqueueSnackbar(`Problema incarcare date: ${e?.message}`, { variant: 'error' });
    } finally {
      setLoading({ ...loading, deps: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra.projectId]);

  useEffect(() => {
    if (!rest.visible) {
      setData(new Order());
    } else {
      loadDependences();
    }
  }, [rest.visible, loadDependences]);

  const handleSetProducts = (pp: ProjectProduct[]): void => {
    setData({ ...data, projectProducts: pp });
  };

  const dateChangeHandler = (date: moment.Moment) => {
    setData({ ...data, docDate: date?.toISOString() });
  };

  const saveHandler = async () => {
    if (!data?.docDate) {
      enqueueSnackbar('Este obligatoriu sa setezi data documentului!', { variant: 'warning' });
      return;
    }
    if (!data?.projectProducts?.length) {
      enqueueSnackbar('Este obligatoriu sa selectezi minim un serviciu!', { variant: 'warning' });
      return;
    }
    setLoading({ ...loading, saving: true });
    try {
      // FIXME: Type not ok
      const created = await api.orders.create(data);
      enqueueSnackbar('Comanda creata cu succes!', { variant: 'success' });
      rest.setNotify(created?.projectContract?.id);
      rest.cancel();
    } catch (e) {
      enqueueSnackbar(`Problema adaugare comanda: ${e?.message}`, { variant: 'error' });
    } finally {
      setLoading({ ...loading, saving: false });
    }
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga comanda"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Box style={{ marginTop: 10 }}>
            <KeyboardDatePicker
              label="Data document"
              name="docDate"
              inputVariant="outlined"
              fullWidth
              value={data?.docDate ? new Date(data?.docDate) : null}
              format={DATE_FORMAT}
              onChange={dateChangeHandler}
            />
          </Box>
          <Box style={{ marginTop: 10 }}>
            <OrderCreateProductsList
              parentId={data.project.id}
              products={data.projectProducts}
              setProducts={handleSetProducts}
            />
          </Box>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={saveHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            loading={loading.saving}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default OrderCreateModal;
