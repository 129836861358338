/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Lab } from 'src/types/lab';
import { Workstation } from 'src/types/workstation';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import FilesDropzone from 'src/components/FilesDropzone';
import useAuth from 'src/hooks/useAuth';
import { ATTACHMENT_TYPE, UploadedFile } from 'src/types/file';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { laboratory?: Lab; data?: Workstation; itemId?: string };
}

const AddWorkstationModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [attachments, setAttachments] = useState<UploadedFile[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [data, setData] = useState<Workstation>(new Workstation());
  const [errors, setErrors] = useState<{ name: boolean; authorisationNo: boolean; address: boolean; files: boolean }>({
    name: false,
    authorisationNo: false,
    address: false,
    files: false
  });

  useEffect(() => {
    if (rest.visible) {
      if (extra.data) setData({ ...extra.data });
    }
  }, [rest.visible, extra.data]);

  useEffect(() => {
    if (!rest.visible) {
      setData(new Workstation());
    }
  }, [rest.visible]);

  const onDrop = useCallback((newAttachments) => {
    setAttachments((prevAttachments) => [...prevAttachments].concat(newAttachments));
  }, []);

  const isValid = (): boolean => {
    const newErrors = { ...errors };
    if (!data.name) {
      newErrors.name = true;
    }
    if (!data.authorisationNo) {
      newErrors.authorisationNo = true;
    }

    if (Object.values(newErrors).some((value: boolean) => value)) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const addAction = async () => {
    if (!isValid()) return;
    setSubmitting(true);
    const messages = {
      success: 'Punct de lucru adaugat cu succes!',
      failed: 'Problema adaugare punct de lucru'
    };
    try {
      if (!extra.laboratory) {
        throw new Error('Nu s-a gasit un laborator.');
      }
      const formData = new FormData();
      if (attachments?.length) {
        attachments.forEach((item) => formData.append('attachment', item));
      }
      const payload = {
        ...data,
        laboratory: extra.laboratory.id
      };
      Object.keys(payload)
        .filter((el) => el !== 'deletedAt')
        .filter((el) => el !== 'id')
        .forEach((key) => formData.append(key, payload[key]));

      await api.workstations.create(formData);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      setSubmitting(false);

      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
      setSubmitting(false);
    }
  };

  const editAction = async () => {
    if (!isValid()) return;
    setSubmitting(true);

    const messages = {
      success: 'Punct de lucru actualizat cu succes!',
      failed: 'Problema actualizare punct de lucru'
    };
    try {
      const formData = new FormData();
      if (attachments?.length) {
        attachments.forEach((item) => formData.append('attachment', item));
      }

      Object.keys(data)
        .filter((el) => el !== 'deletedAt')
        .forEach((key) => formData.append(key, data[key]));
      await api.workstations.update(extra.itemId, formData);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      enqueueSnackbar(messages.success, { variant: 'success' });
      setSubmitting(false);
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
      setSubmitting(false);
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };

  const isEdit = extra?.itemId && extra?.data ? true : false;

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title={isEdit ? 'Editare punct de lucru' : 'Adauga punct de lucru'}
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                label="Denumire"
                name="name"
                fullWidth
                onChange={changeHandler}
                value={data.name}
                variant="outlined"
                InputLabelProps={{ shrink: !!data.name }}
                InputProps={{ inputProps: { min: 0 } }}
                error={errors?.name}
                helperText={errors?.name && 'Denumirea este obligatorie!'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Numar autorizatie"
                name="authorisationNo"
                fullWidth
                onChange={changeHandler}
                value={data.authorisationNo}
                variant="outlined"
                InputLabelProps={{ shrink: !!data.authorisationNo }}
                InputProps={{ inputProps: { min: 0 } }}
                error={errors?.authorisationNo}
                helperText={errors?.authorisationNo && 'Numar autorizatie este obligatoriu!'}
              />
            </Grid>
            <Grid item xs={12}>
              <FilesDropzone
                multiple={false}
                customText="Ataseaza autorizatia pentru acest punct de lucru"
                onDrop={onDrop}
                values={(data.files || []) as UploadedFile[]}
                attachments={attachments}
                filterType={ATTACHMENT_TYPE.WORKSTATION_AUTHORIZATION}
                token={token}
                onEmailSent={() => {}}
                noEmail
                onDelete={async (index: number, fileId: string) => {
                  const FILE_API = api.files;
                  if (fileId) {
                    try {
                      await FILE_API.delete(fileId);
                      setAttachments([]);
                      setData({ ...data, files: [] });
                    } catch (e) {
                      console.error(e);
                    }
                  } else {
                    setAttachments([]);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Adresa locatie"
                name="address"
                fullWidth
                onChange={changeHandler}
                value={data.address}
                variant="outlined"
                InputLabelProps={{ shrink: !!data.address }}
                InputProps={{ inputProps: { min: 0 } }}
                error={errors?.address}
                helperText={errors?.address && 'Adresa este obligatorie!'}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={isEdit ? editAction : addAction}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={submitting}
          >
            {isEdit ? 'SALVEAZA' : 'ADAUGA'}
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default AddWorkstationModal;
