import { Category } from './category';
import { ProjectContract } from './contract';
import { Lab } from './lab';

export class LabCategory {
  id?: string;
  category: Category;
  laboratory: Lab;
  projectContract: ProjectContract;
}
