import { Lab } from './lab';

export class Workstation {
  id?: string;
  name: string = '';
  files?: UploadedFile[];
  authorisationNo: string = '';
  address: string = '';
  laboratory?: Lab;
}
