import React, { FC } from 'react';
import { ProjectContract } from 'src/types/contract';

import { Card, CardContent, Grid, Typography } from '@material-ui/core';

interface Props {
  data: ProjectContract;
  hideProbe?: boolean;
}

const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const PvpInfo: FC<Props> = ({ data, hideProbe }) => {
  const np = data;

  const filteredProbeData = () => {
    const d = np.contract?.docAttributes;
    const field = 'data';
    let keys = Object.keys(d).filter((k) => k.startsWith(field));
    if (hideProbe) keys = keys.filter((k) => !k.includes('.probe'));
    return keys.map((k) => ({
      name: k.replace(`${field}.`, ''),
      value: d[k]
    }));
  };

  return (
    <Grid item xs={12}>
      <Card key={np.id}>
        <CardContent>
          {filteredProbeData().map((el) => (
            <Typography>
              <b>{capitalizeFirstLetter(el.name.replaceAll('_', ' '))}:</b> {el.value || '-'}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PvpInfo;
