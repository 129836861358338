/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Category } from 'src/types/category';
import { Client } from 'src/types/client';
import { Lab } from 'src/types/lab';
import { Project } from 'src/types/project';
import { Report } from 'src/types/report';
import { Site } from 'src/types/site';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
}

const ReportCreateModal: FC<Props> = ({ ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<Report>(new Report());
  const [deps, setDeps] = useState<{
    laboratories: Lab[];
    clients: Client[];
    sites: Site[];
    projects: Project[];
    categories: Category[];
  }>({
    laboratories: [],
    clients: [],
    sites: [],
    projects: [],
    categories: []
  });
  const [projectFilter, setProjectFilter] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const saveHandler = async () => {
    try {
      setLoading(true);
      const created = await api.reports.create(data);
      setLoading(false);
      enqueueSnackbar('Raport creat cu success', { variant: 'success' });
      rest.setNotify(created);
      rest.cancel();
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(`Problema adaugare raport: ${e?.message}`, { variant: 'error' });
    }
  };

  const loadDependences = useCallback(async () => {
    const projects = (
      await api.projects.listPg({
        skip: 0,
        joinColumns: ['client', 'site', 'laboratory'],
        selectedColumns: [
          'id',
          'pno',
          'client.id',
          'client.companyName',
          'client.vat',
          'client.registrationNo',
          'site.id',
          'site.objectiveName',
          'site.addressCounty',
          'site.addressStreetName',
          'laboratory.id',
          'laboratory.companyName',
          'laboratory.vat',
          'laboratory.registrationNo'
        ]
      })
    ).result;
    const categories = (await api.categories.list()).result;
    const laboratories: Lab[] = [];
    const sites: Site[] = [];
    const clients: Client[] = [];
    projects.forEach((p) => {
      if (p.laboratory) laboratories.push(p.laboratory);
      if (p.client) clients.push(p.client);
      if (p.site) {
        p.site.client = { id: p.client.id };
        sites.push(p.site);
      }
    });
    setDeps({
      laboratories: applyFilterById(laboratories),
      sites: applyFilterById(sites),
      clients: applyFilterById(clients),
      projects: projects,
      categories
    });
    setProjectFilter(projects);
  }, []);

  useEffect(() => {
    if (!rest.visible) {
      setData(new Report());
    } else {
      loadDependences();
    }
  }, [rest.visible, loadDependences]);

  const changeHandler =
    (field: string, name: string) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      let newData = { ...data, [name]: deps[field].find((d) => d.id === event.target.value) };
      if (name === 'category') {
        setData(newData);
        return;
      }
      switch (name) {
        case 'site':
          newData.client = { id: '' } as Client;
        case 'client':
          newData.laboratory = { id: '' } as Lab;
        case 'laboratory':
          newData.project = { id: '' } as Project;
      }
      const nameFilters = value && name !== 'project' ? [{ name, value }] : [];
      if (newData.client?.id) nameFilters.push({ name: 'client', value: newData.client.id });
      if (newData.site?.id) nameFilters.push({ name: 'site', value: newData.site.id });
      if (newData.laboratory?.id) nameFilters.push({ name: 'laboratory', value: newData.laboratory.id });
      let projectFilter = [...deps.projects];
      nameFilters.forEach((nf) => {
        projectFilter = projectFilter.filter((p) => p[nf.name]?.id === nf.value);
      });
      if (name === 'project' && value) {
        const project = deps.projects.find((p) => p.id === value);
        newData.site = project.site;
        newData.client = project.client;
        newData.laboratory = project.laboratory;
        projectFilter = [project];
      }
      setProjectFilter(projectFilter);
      setData(newData);
    };

  const getListData = (displayField, prefix) => {
    let listData = displayField ? [...deps[prefix]] : [];
    switch (prefix) {
      case 'clients':
        if (data.site?.id) listData = listData.filter((i) => i.id === data.site?.client?.id);
        if (data.laboratory?.id)
          listData = listData.filter((client) => projectFilter.find((p) => p.client?.id === client.id));
        break;
      case 'sites':
        if (data.client?.id) listData = listData.filter((i) => i.client.id === data.client?.id);
        if (data.laboratory?.id)
          listData = listData.filter((site) => projectFilter.find((p) => p.site?.id === site?.id));
        break;
      case 'laboratories':
        if (data.client?.id || data.site?.id)
          listData = listData.filter((lab) => projectFilter.find((p) => p.laboratory?.id === lab?.id));
        break;
      case 'projects':
        listData = projectFilter;
        break;
    }
    return listData;
  };

  const renderForm = (title, label, name, prefix, displayField, table, type?) => {
    // FIXME: Add display data to state
    const listData = getListData(displayField, prefix);
    return (
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Card>
          <CardHeader title={title} />
          <Divider />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={label}
                  name={name}
                  onChange={changeHandler(prefix, name)}
                  select={!!displayField}
                  SelectProps={{ native: true }}
                  value={displayField ? data?.[name]?.id : data?.[name]}
                  variant="outlined"
                  InputLabelProps={{ shrink: !displayField ? !!data?.[name] : !!data?.[name]?.id }}
                  // disabled={listData.length === 0}
                >
                  {displayField && <option key="" value=""></option>}
                  {displayField &&
                    listData.map((value) => (
                      <option key={value.id} value={value.id}>
                        {value[displayField]}
                      </option>
                    ))}
                </TextField>
              </Grid>
              {!!data?.[name]?.id && !!table.length && (
                <Grid item xs={12}>
                  <Table>
                    <TableBody>
                      {table.map((t) => (
                        <TableRow key={t.i}>
                          <TableCell>{t.i}</TableCell>
                          <TableCell>{data?.[prefix]?.[t.f]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga raport"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <>
            {renderForm('Santier', 'Selecteaza santier', 'site', 'sites', 'objectiveName', [])}
            {renderForm('Client', 'Selecteaza client', 'client', 'clients', 'companyName', [])}
            {renderForm('Laborator', 'Selecteaza laborator', 'laboratory', 'laboratories', 'companyName', [])}
            {renderForm('Proiect', 'Selecteaza proiect', 'project', 'projects', 'pno', [])}
            {renderForm('Profil', 'Selecteaza profil', 'category', 'categories', 'name', [])}
          </>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={saveHandler}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={
              !data.project?.id || !data.category?.id || !data.client?.id || !data.site?.id || !data.laboratory?.id
            }
            loading={loading}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

function applyFilterById(data) {
  return data.filter((item, index, array) => array.findIndex((a) => a.id === item.id) === index);
}

export default ReportCreateModal;
