import { uniqBy } from 'lodash';
import { TemplateAttribute } from 'src/types/contract';

export const extractTemplateVariables = (
  template: string,
  regex = /{{(.*?)}}/g,
  separator = '::'
): TemplateAttribute[] => {
  let match: RegExpExecArray | null;
  const extracted: TemplateAttribute[] = [];
  while ((match = regex.exec(template))) {
    let item: TemplateAttribute = { key: match[1] };
    if (separator) {
      const [key, type] = item.key.split(separator);
      item = { key, type };
    }
    extracted.push(item);
  }
  const uniqueExtracted = uniqBy(extracted, 'key');
  return uniqueExtracted;
};

export const extractAttributes = (probeFieldString: string, attributes: TemplateAttribute[], key: string) => {
  const extractedAttr = extractTemplateVariables(probeFieldString, /{{(.*?)}}/g, '::');
  if (extractedAttr.length) {
    extractedAttr.forEach((f) => {
      attributes.push({
        key: `${key}.${f.key}`,
        type: f.type
      });
    });
  }
};

export const formatLabelText = (text: string, labelReplace = 'data.'): string => {
  if (!text) return '';
  // eslint-disable-next-line no-param-reassign
  text = text.replace(labelReplace, '');
  return text.charAt(0).toUpperCase() + text.slice(1).replace(/_/g, ' ');
};

export const getProbeStringAttr = (attr: TemplateAttribute[], prefix = 'probe.'): string => {
  let str = '';
  if (attr?.length)
    attr.forEach((a) => {
      if (a.key.indexOf(prefix) === 0) {
        str += `{{${a.key.slice(prefix.length)}${(a.type && `::${a.type}`) || ''}}} `;
      }
    });
  return str;
};
