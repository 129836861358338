import React, { FC } from 'react';
import { DocumentAttributes } from 'src/types/contract';
import { Probe } from 'src/types/probe';
import formatDateUtil from 'src/utils/formatDate';

import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { Label } from '@material-ui/icons';

interface Props {
  data: Probe;
  hideTitle?: boolean;
}

const filteredProbeData = (data: DocumentAttributes, field: string) =>
  Object.keys(data)
    .filter((k) => k.startsWith(field) && !k.includes('.productsIds'))
    .map((k) => ({
      name: k.replace(`${field}.`, ''),
      value: data[k]
    }));

const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

const ProbeInfo: FC<Props> = ({ data, hideTitle }) => {
  const np = data;

  return (
    <Grid item xs={6}>
      <Card key={np.id}>
        {!hideTitle && (
          <CardHeader
            title={
              <div>
                Proba #{np.pno}
                {np.canceled ? <Label color="error">Anulata</Label> : null}
              </div>
            }
          />
        )}
        <CardContent>
          {filteredProbeData(np.attributes, 'data').map((el) => (
            <Typography>
              <b>{capitalizeFirstLetter(el.name.replaceAll('_', ' '))}:</b> {el.value || '-'}
            </Typography>
          ))}

          {np.registryDate && (
            <>
              <Typography>
                <b>Data receptiei</b>: {formatDateUtil(`${np.registryDate}`)}
              </Typography>
              <Typography>
                <b>Nume executant</b>: {np.registryManager}
              </Typography>
              <Typography>
                <b>Starea probei</b>: {np.registryEvaluation}
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProbeInfo;
