import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { ProjectContract } from 'src/types/contract';
import { Probe } from 'src/types/probe';
import { User } from 'src/types/user';

import {
    Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, IconButton,
    makeStyles, Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ProbeInfo from './ProbeInfo';
import PvpInfo from './PvpInfo';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  },
  card: {}
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { probe: Probe; user: User; edit?: boolean };
}

const ProbeViewRegistryModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [probes, setProbes] = useState<Probe[]>([]);
  const [template, setTemplate] = useState<ProjectContract>();
  const [pvp, setPvp] = useState<ProjectContract>();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkAllProbes, setCheckAllProbes] = useState<boolean>(false);
  const [allProbes, setAllProbes] = useState<Probe[]>([]);
  const [cProbe, setCProbe] = useState<Probe>();

  const handleChangeAllProbes = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newCheckAllProbes = !checkAllProbes;
    setCheckAllProbes(newCheckAllProbes);
    if (newCheckAllProbes) {
      setProbes([...allProbes]);
    } else {
      setProbes([cProbe]);
    }
  };
  const loadDependences = useCallback(async () => {
    setLoading(true);
    try {
      const dbProbes = (await api.probes.listPg({ criteria: { pvpId: extra?.probe?.pvp?.id } })).result;
      const dbPvp = await api.projectContracts.get(extra?.probe?.pvp?.id);
      const dbProbe = dbProbes.find((p) => p.id === extra.probe?.id);
      let temp = null;
      try {
        temp = await api.projectContracts.get(dbProbe.extra?.['extra.templateId']);
      } catch (e) {
        enqueueSnackbar('Problema incarcare template PV', { variant: 'error' });
      }

      setTemplate(temp);
      setProbes([dbProbe]);
      setAllProbes([...dbProbes]);
      setCProbe(dbProbe);
      setPvp(dbPvp);
    } catch (e) {
      enqueueSnackbar('Problema incarcare probe', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [extra, enqueueSnackbar]);

  useEffect(() => {
    if (rest.visible) setTimeout(loadDependences, 100);
  }, [rest.visible, extra, loadDependences]);

  const close = (withoutNotify?: boolean) => () => {
    rest.cancel();
    setTimeout(() => {
      if (!withoutNotify) rest.setNotify(new Date().getTime());
      setProbes([]);
    }, 500);
  };

  const clientCompanyName = probes?.[0]?.clientCompanyName;
  const pvpString = extra?.probe?.pvp?.pno;

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Vizualizeaza receptie proba"
          action={
            <IconButton aria-label="close" onClick={close(true)}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Typography>
            <b>Client: </b>
            {clientCompanyName || 'Se incarca...'}
          </Typography>
          <Typography style={{ marginBottom: 10 }}>
            <b>Proces verbal de prelevare:</b> {pvpString} ({template?.contract?.title})
          </Typography>
          {pvp && <PvpInfo data={pvp} />}
          <FormControlLabel
            control={<Checkbox checked={checkAllProbes} onChange={handleChangeAllProbes} />}
            label="Vizualizeaza toate probele din PV-ul curent"
          />

          <Grid container spacing={4}>
            {loading ? (
              <Typography style={{ padding: 10 }}>Se incarca ...</Typography>
            ) : (
              probes.map((np, index) => <ProbeInfo data={np} />)
            )}
          </Grid>
        </CardContent>
      </Card>
    </DialogAdvanced>
  );
};

export default ProbeViewRegistryModal;
