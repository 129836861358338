import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { ChangeEvent, FC, useState } from 'react';
import { Edit as EditIcon, Search, Trash as TrashIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Dialog from 'src/components/Dialog';
import formatDateUtil from 'src/utils/formatDate';
import getPropByString from 'src/utils/getPropByString';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  makeStyles,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core';

import Label from './Label';
import MenuButton from './MenuButton';

interface ResultsProps {
  className?: string;
  data: any[];
  columns: {
    id: string;
    label: string;
    props?: {
      [key: string]: string | number;
    };
    style?: {
      [key: string]: string | number;
    };
    render?: (value: string | number | boolean, item?: object) => any;
    // [key: string]: string | number;
  }[];
  rows?: {
    id: string;
    label: string;
    props?: {
      [key: string]: string | number;
    };
    style?: {
      [key: string]: string | number;
    };
    render?: (value: string | number | boolean, item?: object) => any;
    // [key: string]: string | number;
  }[];
  isElementTouchable?: boolean;
  onTouchAction?: (id: string) => void;
  rightBadgeLabel?: string;
  rightBadgeColor?: 'primary' | 'secondary' | 'error' | 'warning' | 'success';
  rightBadgeType?: string;
  editRoute?: string;
  customActions?: { handler: (id: string) => void; icon: React.ReactElement; label: string }[];
  deleteAction?: (id: string) => void;
  title: string;
  action?: any;
  height?: number | string;
  search?: string;
  customDeleteIdField?: string;
  filter?: { field: string; type: string; default: any[]; label: string; values: any[] };
  heightByContent?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  touchableCell: {
    cursor: 'pointer',
    width: '100%'
  },
  itemRow: {
    padding: '2px 0px',
    display: 'flex',
    flexDirection: 'row'
  },
  itemRowSingle: {
    padding: '2px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%'
  },
  customCell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
    padding: '4px 0px'
  }
}));

const STableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const STableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: lighten(theme.palette.action.hover, 0.3)
    }
  }
}))(TableRow);

const Results: FC<ResultsProps> = ({
  className,
  columns,
  rows,
  isElementTouchable,
  onTouchAction,
  rightBadgeLabel,
  rightBadgeColor,
  rightBadgeType,
  data,
  editRoute,
  deleteAction,
  title,
  action,
  customActions,
  height,
  search,
  customDeleteIdField,
  filter,
  heightByContent,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const [deleteRequest, setDeleteRequest] = useState({ visible: false, itemId: null });
  const [searchKey, setSearchKey] = useState('');
  const [filterValue, setFilterValue] = useState<any[]>(filter?.default);

  const onDelete = (itemId: string) => () => {
    setDeleteRequest({ visible: true, itemId });
  };

  const confirmDeleteAction = () => {
    if (deleteRequest.itemId) {
      deleteAction(deleteRequest.itemId);
      resetDeleteRequest();
    }
  };

  const resetDeleteRequest = () => {
    setDeleteRequest({ visible: false, itemId: null });
  };

  const computeColumnStyle = (column) => {
    const { style } = column;
    if (style) return style;
    return { maxWidth: 90 };
  };

  const setSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target) return;
    setSearchKey(event.target.value);
  };

  const renderDesktopView = () => {
    if (!!data.length) {
      return (
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <STableCell style={computeColumnStyle(column)} key={index + 'B'}>
                  {column.label}
                </STableCell>
              ))}
              {hasActions && (
                <STableCell align="right" style={{ maxWidth: 90 }}>
                  Actiuni
                </STableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{renderColumns()}</TableBody>
        </Table>
      );
    }
    return null;
  };

  const renderColumns = () =>
    data.map((item) => (
      <STableRow key={item.id} hover>
        {columns.map((column, index) => (
          <STableCell style={computeColumnStyle(column)} key={index + 'A'}>
            {column.render ? column.render(getPropByString(item, column.id), item) : getPropByString(item, column.id)}
          </STableCell>
        ))}
        {hasActions && (
          <STableCell align="right" style={{ maxWidth: 90 }} key="custom">
            {customActions &&
              customActions.map((ca) => (
                <IconButton onClick={() => ca.handler(item.id)} key="item.id">
                  <SvgIcon fontSize="small">{ca.icon}</SvgIcon>
                </IconButton>
              ))}
            {editRoute && (
              <IconButton component={RouterLink} to={`${editRoute}/${item.id}`} key="editRoute">
                <SvgIcon fontSize="small" color="primary">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            )}
            {deleteAction && (
              <IconButton
                onClick={onDelete(customDeleteIdField ? item[customDeleteIdField].id : item.id)}
                key="deleteAction"
              >
                <SvgIcon fontSize="small" color="error">
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}
          </STableCell>
        )}
      </STableRow>
    ));

  const renderMobileView = () => {
    if (!rows?.length) {
      return renderDesktopView();
    }
    if (!!data.length) {
      return (
        <Table stickyHeader size="small">
          <TableBody>{renderRows()}</TableBody>
        </Table>
      );
    }
    return null;
  };

  const onClickHandler = (id: string) => () => {
    if (!!onTouchAction) {
      onTouchAction(id);
    } else {
      const path = `${window.location.pathname}/${id}`;
      history.push(path);
    }
  };

  const renderRows = () =>
    data.map((item) => {
      const badgeValue =
        item?.[rightBadgeLabel] && rightBadgeType === 'date'
          ? formatDateUtil(item?.[rightBadgeLabel], true)
          : item?.[rightBadgeLabel] || '';
      return (
        <STableRow key={item.id} hover>
          <STableCell className={classes.customCell}>
            <Box
              className={isElementTouchable && classes.touchableCell}
              onClick={isElementTouchable && onClickHandler(item.id)}
            >
              {rows.map((row, index) => (
                <Box className={rows.length > 1 ? classes.itemRow : classes.itemRowSingle} key={item.id + index}>
                  {row.label && row.label !== '' && (
                    <Typography style={{ fontWeight: 700, width: '30%', minWidth: 100 }}>{row.label}</Typography>
                  )}
                  <Typography style={{ marginLeft: 4, width: '70%' }}>
                    {row.render ? row.render(getPropByString(item, row.id), item) : getPropByString(item, row.id)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={classes.rightColumn}>
              {hasActions ? (
                <MenuButton
                  customActions={customActions}
                  editPath={editRoute && `${editRoute}/${item.id}`}
                  deleteAction={deleteAction && onDelete(item.id)}
                  itemId={item.id}
                />
              ) : (
                <div />
              )}
              {badgeValue && <Label color={rightBadgeColor || 'primary'}>{badgeValue}</Label>}
            </Box>
          </STableCell>
        </STableRow>
      );
    });

  const hasActions = editRoute || deleteAction || customActions;
  if (data.length && search && searchKey && searchKey !== '')
    data = data?.filter((d) => d[search].match(new RegExp(searchKey, 'i')));
  if (filter) {
    data = data?.filter((d) =>
      filterValue.includes(customDeleteIdField ? d[customDeleteIdField][filter?.field] : d[filter?.field])
    );
  }
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader title={title} action={action} />
        <Divider />
        <Box alignItems="center" display="flex" flexWrap="wrap" m={-1} p={2}>
          {search?.length && (
            <Box m={1} maxWidth="100%" width={400}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  )
                }}
                placeholder="Cautare..."
                variant="outlined"
                onChange={setSearch}
                value={searchKey}
              />
            </Box>
          )}
          {filter?.type === 'select' && (
            <Box m={1} maxWidth="100%" width={200}>
              <TextField
                fullWidth
                label={filter.label}
                onChange={(e) => setFilterValue(e.target.value)}
                value={filterValue}
                select
                SelectProps={{ native: true }}
                variant="outlined"
                defaultValue={filter.default}
              >
                {filter.values.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
          )}
        </Box>
        <PerfectScrollbar>
          <Box
            minWidth={isMobileDevice && !!rows?.length ? null : 800}
            style={
              heightByContent
                ? { maxHeight: height || 'calc(100vh - 300px)' }
                : { height: height || 'calc(100vh - 300px)' }
            }
          >
            {!data.length && (
              <Typography variant="h4" style={{ margin: 20 }}>
                Nu sunt date disponibile.
              </Typography>
            )}
            {isMobileDevice ? renderMobileView() : renderDesktopView()}
          </Box>
        </PerfectScrollbar>
      </Card>
      <Dialog
        visible={deleteRequest.visible}
        title="STERGERE"
        question="Sunteti sigur ca doriti stergerea?"
        actionTitle="Sterge"
        cancel={resetDeleteRequest}
        action={confirmDeleteAction}
      />
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  data: []
};

export default Results;
