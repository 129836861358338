/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import api from 'src/lib/api';
import { Attachments, Email } from 'src/types/email';

import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import EmailEditForm from './EmailEditForm';

const allLabels = {
  pageTitle: 'Trimite email',
  messages: {
    success: 'Email trimis cu succes!',
    fail: 'Problema trimitere email'
  },
  cardTitle: 'Trimite email',
  submitLabel: 'Trimite'
};

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { email: Email; token?: string };
}

const EmailModal: FC<Props> = ({ extra, ...rest }) => {
  const [currentFiles, setCurrentFiles] = useState<Attachments[]>(extra.email?.attachments || []);

  useEffect(() => {
    if (!rest.visible) {
      setCurrentFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.visible]);

  const deleteFile = (index: number) => {
    const newFiles = [...currentFiles];
    newFiles.splice(index, 1);
    setCurrentFiles(newFiles);
  };

  const saveHandler = async (entity: Email) => {
    if (extra?.email?.attachment) entity.attachment = extra.email.attachment;
    if (currentFiles.length) entity.attachments = currentFiles;
    const email = await api.emails.create(entity);
    rest.setNotify(email);
    rest.cancel();
    return email;
  };

  return (
    <DialogAdvanced {...rest}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EmailEditForm
            entity={extra?.email}
            attachments={currentFiles}
            token={extra?.token}
            action={saveHandler}
            deleteFile={deleteFile}
            labels={allLabels}
            cardHeaderAction={
              <IconButton aria-label="close" onClick={rest.cancel}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
    </DialogAdvanced>
  );
};

export default EmailModal;
