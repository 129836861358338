import { Client } from './client';
import { ProjectContract } from './contract';
import { Lab } from './lab';
import { ProjectProduct } from './product';
import { Project } from './project';
import { Site } from './site';

export enum ORDER_STATUS {
  new = 'new',
  in_progress = 'in_progress',
  done = 'done'
}

export enum ORDER_STATUS_LABEL {
  new = 'Noua',
  in_progress = 'Progres',
  done = 'Finalizata'
}

export class Order {
  id?: string;
  no?: number;
  pno?: string | null;
  created?: Date;
  updated?: Date;
  projectContract?: ProjectContract;
  projectProducts: ProjectProduct[];
  laboratory: Lab;
  client: Client;
  site: Site;
  project: Project;
  element: string;
  status?: ORDER_STATUS;
  samplingDate: string = new Date().toISOString();
  docDate: string = new Date().toISOString();

  constructor(id?: string) {
    if (id) this.id = id;
    this.laboratory = { id: '' } as Lab;
    this.client = { id: '' } as Client;
    this.site = { id: '' } as Site;
    this.project = { id: '' } as Project;
    this.element = '';
    this.projectProducts = [];
    delete this.id;
    delete this.created;
    delete this.updated;
    delete this.projectContract;
    delete this.status;
    delete this.no;
  }
}
