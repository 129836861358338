/* eslint-disable no-fallthrough */

import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import api from 'src/lib/api';
import { Theme } from 'src/theme';
import { Lab } from 'src/types/lab';
import { User } from 'src/types/user';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) => ({
  bg: { backgroundColor: theme.palette.background.dark }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { user: User };
}

const AddLabModal: FC<Props> = ({ extra, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = useState<Lab[]>([]);
  const [currentLab, selectlab] = useState<Lab>(null);

  const getList = useCallback(async () => {
    try {
      if (isMountedRef.current) {
        const response = await api.labs.list();
        setList(response.result);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getList();
  }, [getList]);

  const addlabAction = async () => {
    const messages = {
      success: 'Adaugare cu succes!',
      failed: 'Problema adaugare'
    };
    try {
      const data = {
        ...extra.user,
        laboratories: [...extra.user?.laboratories, currentLab]
      };
      await api.users.update(extra.user?.id, data);
      rest.setNotify(new Date().getTime());
      rest.cancel();
      selectlab(null);
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const getUniqueElements = (arr1: Array<object>, arr2: Array<object>) => {
    const elements = [];
    arr1.forEach((el1: Lab) => {
      if (arr2.findIndex((el2: Lab) => el2.id === el1.id) === -1) elements.push(el1);
    });
    return elements;
  };

  const listData = getUniqueElements(list, extra.user?.laboratories);
  const parsedData = listData?.map((element) => ({ key: element.id, value: element.companyName }));

  const changeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: { key: string; value: string }
  ) => {
    const lab = listData.find((el: Lab) => el?.id === value?.key);
    if (lab) {
      selectlab(lab);
    }
  };

  return (
    <DialogAdvanced {...rest}>
      <Card className={classes.bg}>
        <CardHeader
          title="Adauga laborator"
          action={
            <IconButton aria-label="close" onClick={rest.cancel}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Autocomplete
                id="lab"
                options={parsedData}
                value={{ key: currentLab?.id, value: currentLab?.companyName } || ''}
                getOptionSelected={
                  (option: { key: string; value: string }, value: { key: string; value: string }) =>
                    value?.key === option.key || !value?.key
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                getOptionLabel={(option: { key: string; value: string }) => option.value || ''}
                onChange={changeHandler}
                autoHighlight
                blurOnSelect
                renderInput={(params) => <TextField {...params} fullWidth label="Laborator" variant="outlined" />}
              />
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Divider />
          <Button
            onClick={addlabAction}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<SaveOutlinedIcon />}
            style={{ margin: 20 }}
            disabled={!currentLab}
          >
            Adauga
          </Button>
        </div>
      </Card>
    </DialogAdvanced>
  );
};

export default AddLabModal;
