import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';

const formatDateUtil = (value: string | Date, isShort?: boolean, separator = '/') => {
  if (!value) return '';
  if (isShort) return moment(value).format(`DD${separator}MM${separator}YYYY`);
  return moment(value).format('DD/MM/YYYY h:mm');
};

export default formatDateUtil;
