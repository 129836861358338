/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import { UploadedFile } from './file';
import { Lab } from './lab';
import { Project } from './project';

export enum CONTRACT_TYPE {
  contract = 'contract',
  offer = 'offer',
  order_template = 'order_template',
  order = 'order',
  report_template = 'report_template',
  report = 'report',
  pvp_template = 'pvp_template',
  pvp = 'pvp',
  worksheet = 'worksheet',
  worksheet_template = 'worksheet_template'
}

export class TemplateAttribute {
  key: string;
  type?: string;
}

export class DocumentAttributes {
  [key: string]: string | number | Date | Object;
}
export class Contract {
  id?: string;
  title?: string;
  content?: string;
  header?: string;
  footer?: string;
  pdf?: UploadedFile;
  laboratory?: Lab;
  project?: Project;
  docAttributes?: DocumentAttributes;
  tempAttributes?: TemplateAttribute[];
  contractType: CONTRACT_TYPE = CONTRACT_TYPE.contract;

  constructor(contractType: CONTRACT_TYPE, laboratory?: Lab, project?: Project, title?: string) {
    this.contractType = contractType;
    if (laboratory) this.laboratory = laboratory;
    if (project) this.project = project;
    if (title) this.title = title;
  }
}

export class ProjectContract {
  id?: string;
  no?: number;
  pno?: string;
  contractType?: CONTRACT_TYPE;
  project: Project;
  contract: Contract;
  version?: number;
  active?: boolean;
  canceled?: boolean;
  laboratory?: Lab;
}
