import { useContext } from 'react';
import PaginatedListExportModal from 'src/components/PaginatedListExportModal';
import TextEditorModal from 'src/components/TextEditorModal';
import GlobalModalContext from 'src/contexts/GlobalModalContext';
import EmailModal from 'src/views/emails/EmailModal';
import AddWorkstationModal from 'src/views/labs/LabEditView/AddWorkstationModal';
import CategoryTemplateModal from 'src/views/labs/LabEditView/CategoryTemplateModal';
import OrderCreateModal from 'src/views/orders/OrderListView/OrderCreateModal';
import ProbeAddRegistryModal from 'src/views/probes-registry/ProbeAddRegistryModal';
import ProbeViewRegistryModal from 'src/views/probes-registry/ProbeViewRegistryModal';
import ProductModal from 'src/views/products/ProductModal';
import ProjectPVPCreateModal from 'src/views/projects/ProjectEditView/ProjectPVPCreateModal';
import ProjectReportCreateModal from 'src/views/projects/ProjectEditView/ProjectReportCreateModal';
import ProjectWorksheetCreateModal from 'src/views/projects/ProjectEditView/ProjectWorksheetCreateModal';
import ReportCreateModal from 'src/views/reports/ReportListView/ReportCreateModal';
import AddClientModal from 'src/views/user/AddClientModal';
import AddLabModal from 'src/views/user/AddLabModal';

export enum MODALS {
  PRODUCT,
  EMAIL,
  ORDER_CREATE,
  REPORT_CREATE,
  PROJECT_REPORT_CREATE,
  PROJECT_WORKSHEET_CREATE,
  PROJECT_PVP_CREATE,
  ADD_CLIENT,
  ADD_LAB,
  ADD_WORKSTATION,
  PROBE_ADD_REGISTRY,
  EXPORT_EXCEL,
  TEXT_EDITOR,
  MAPPING_CATEGORY_TEMPLATE,
  PROBE_VIEW_REGISTRY
}

export const MODALS_COMP = {
  [MODALS.PRODUCT]: ProductModal,
  [MODALS.EMAIL]: EmailModal,
  [MODALS.ORDER_CREATE]: OrderCreateModal,
  [MODALS.REPORT_CREATE]: ReportCreateModal,
  [MODALS.PROJECT_REPORT_CREATE]: ProjectReportCreateModal,
  [MODALS.PROJECT_WORKSHEET_CREATE]: ProjectWorksheetCreateModal,
  [MODALS.PROJECT_PVP_CREATE]: ProjectPVPCreateModal,
  [MODALS.ADD_CLIENT]: AddClientModal,
  [MODALS.ADD_LAB]: AddLabModal,
  [MODALS.ADD_WORKSTATION]: AddWorkstationModal,
  [MODALS.PROBE_ADD_REGISTRY]: ProbeAddRegistryModal,
  [MODALS.PROBE_VIEW_REGISTRY]: ProbeViewRegistryModal,
  [MODALS.EXPORT_EXCEL]: PaginatedListExportModal,
  [MODALS.TEXT_EDITOR]: TextEditorModal,
  [MODALS.MAPPING_CATEGORY_TEMPLATE]: CategoryTemplateModal
};

const useGlobalModal = () => useContext(GlobalModalContext);

export default useGlobalModal;
